<template>
  <div>
    <v-simple-table class="table mt-2" height="81vh">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Product</th>
            <th class="text-left">Variant</th>
            <th class="text-left">Modifiers</th>
            <th class="text-left">Quantity</th>
            <th class="text-left">Unit Price</th>
            <th class="text-left">Total</th>
            <th class="text-left">date</th>
            <th class="text-left">time</th>
            <th class="text-left"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(value, index) in variants" :key="index">
            <td>{{ getValue("name", index) }}</td>
            <td>{{ getValue("variant", index) }}</td>
            <td>{{ getValue("modifier", index) }}</td>
            <td>{{ value.Default.Default.quantity }}</td>
            <td>{{ value.Default.Default.amount }}</td>
            <td>Tue 20/4/21</td>
            <td>1.00 PM</td>
            <td>
              {{
                parseFloat(
                  value.Default.Default.quantity * value.Default.Default.amount
                ).toFixed(2)
              }}
            </td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="blue" v-bind="attrs" v-on="on">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon color="error" v-bind="attrs" v-on="on">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Remove</span>
              </v-tooltip>
              <!-- <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon color="green" v-bind="attrs" v-on="on">
                      <v-icon>mdi-account-arrow-left</v-icon>
                    </v-btn>
                  </template>
                  <span>Impersonate</span>
                </v-tooltip> -->
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
export default {
  name: "ManageOrders",
  data() {
    return {
      orders: null,
      show_order: "60347aa05254b10008fc60ec",
      variants: {
        "60347d4d5254b10008fc6113": {
          Default: { Default: { amount: 3.2, quantity: 1 } },
        },
        "60347bdc5254b10008fc60f8": {
          Default: { Default: { amount: 3.5, quantity: 1 } },
        },
        "60347e7a5254b10008fc611b": {
          Default: { Default: { amount: 1.2, quantity: 1 } },
        },
      },
      products: [
        {
          _id: "60347d4d5254b10008fc6113",
          __v: 0,
          availability: [],
          description: "",
          fire_id: "Hysnz7ddK6UvrPivVcx1oYe22Ic2",
          media_urls: [
            {
              _id: "60347d4d5254b10008fc6114",
              original: "60347d4d5254b10008fc6112.jpeg",
            },
          ],
          modifier_sets: [],
          name: "Garam",
          outlets: [],
          source: "app",
          tags: ["60347b775254b10008fc60f6"],
          unit_price: 3.2,
          variants: [],
          id: "60347d4d5254b10008fc6113",
        },
        {
          _id: "60347bdc5254b10008fc60f8",
          __v: 0,
          availability: [],
          description: "",
          fire_id: "Hysnz7ddK6UvrPivVcx1oYe22Ic2",
          media_urls: [
            {
              _id: "60347bf65254b10008fc60fb",
              original: "60347bf55254b10008fc60fa.jpeg",
            },
          ],
          modifier_sets: [],
          name: "Gula",
          outlets: [],
          source: "app",
          tags: ["60347b775254b10008fc60f6"],
          unit_price: 3.5,
          variants: [],
          id: "60347bdc5254b10008fc60f8",
        },
        {
          _id: "60347e7a5254b10008fc611b",
          __v: 0,
          availability: [],
          description: "Gardenia Delicia Sambal Bilis Bun 60g",
          fire_id: "Hysnz7ddK6UvrPivVcx1oYe22Ic2",
          media_urls: [
            {
              _id: "60347e7a5254b10008fc611c",
              original: "60347e7a5254b10008fc611a.jpeg",
            },
          ],
          modifier_sets: [],
          name: "Gardenia Sambal Bilis",
          outlets: [],
          source: "app",
          tags: ["60347cba5254b10008fc6109"],
          unit_price: 1.2,
          variants: [],
          id: "60347e7a5254b10008fc611b",
        },
      ],
    };
  },

  methods: {
    getOut(i) {
      console.log(i);
    },

    returnDay(i) {
      return dayjs(i).format("DD/MM/YY h:mmA");
    },

    getValue(variant, i) {
      let x = this.products.find((e) => e._id == i);
      switch (variant) {
        case "name":
          return x.name;
        case "variant":
          return x.variants.length !== 0 ? "Default" : "Variant";
        case "modifier":
          return x.modifier_sets.length !== 0 ? "Default" : "Modifiers";
      }
    },

    returnDate(i) {
      return dayjs(i).format("ddd D/M/YY");
    },

    returnTime(i) {
      return dayjs(i).format("h:mmA");
    },
  },

  beforeMount() {
    let days = {};
    this.products.forEach((o) => {
      let day = dayjs(o.createdAt).format("YYYY-MM-DD");
      days[day] = [...(days[day] || []), o];
    });
    this.orders = days;
    // console.log(this.orders);
  },
};
</script>
<style lang="scss" scoped>
.table {
  overflow-y: auto;
}
</style>
